import { cookie } from 'sf-modules';
import base64 from 'base-64';
import {
    AUTHENTICATE,
    CHECKOUT_RESTORE,
    SET_GUEST_CUSTOMER,
    SET_STORE_AS_INITIALIZED,
} from '@yema/commerce/store/types';

const rebuildStoreFromCookies = (dispatch, ctx = null) => {
    const req = ctx ? ctx.req : null;
    let authInfos = cookie.get(process.env.NEXT_PUBLIC_COOKIE_AUTH, req);
    const checkoutInfos = cookie.get(process.env.NEXT_PUBLIC_COOKIE_CHECKOUT_ID, req);

    let actionsToDispatch = [];
    // RE AUTHENTICATE
    if (authInfos) {
        authInfos = JSON.parse(decodeURIComponent(base64.decode(authInfos)));
        actionsToDispatch.push({
            type: AUTHENTICATE,
            payload: authInfos,
        });
    } else {
        actionsToDispatch.push({ type: SET_GUEST_CUSTOMER });
    }

    // REBUILD THE CART
    actionsToDispatch.push({
        type: CHECKOUT_RESTORE,
        payload: {
            checkoutID: checkoutInfos,
        },
    });

    dispatch(actionsToDispatch);
};

export default function initializeUserContext({ authToken, dispatch, context = null }) {
    const isServer = typeof window === 'undefined';
    if (isServer || !authToken) rebuildStoreFromCookies(dispatch, isServer ? context : null);

    dispatch({ type: SET_STORE_AS_INITIALIZED });
}
