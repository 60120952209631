import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from '@yema/commerce/StoreClient';
import { initialize } from 'utils/initialize';
import initializeUserContext from 'utils/initializeUserContext';
import { trackError } from '@yema/commerce/utils/error';
import { get_ } from 'sf-modules';
import usePixelSoicosRetargeting from 'hooks/usePixelSoicosRetargeting';
import { getHomepage } from 'functions/fnRequestAPIs/fnCMSPagesAPI';
import { getMenus } from 'functions/fnRequestAPIs/fnMenus';
import NotFoundLayout from 'layouts/NotFound.layout';
import PageLayout from 'layouts/Page.layout';
import CMS_Page from 'components/cms/CMS_Page';
import { FB_PAGE, TWITTER_PAGE, INSTAGRAM_PAGE, LINKEDIN_PAGE } from 'constants/constants';

const homepageSchemaMarkup = {
    __html: JSON.stringify({
        '@context': 'https://schema.org/',
        '@type': 'LocalBusiness',
        name: 'YEMA',
        description: 'YEMA: el primer súper consciente de México. Así de bueno. Así de fácil.',
        image: {
            '@type': 'ImageObject',
            url: 'https://storage.googleapis.com/yema-plm/cms-prod/original_images/bf-mundoYEMA.jpg',
        },
        url: process.env.NEXT_PUBLIC_URL,
        sameAs: [FB_PAGE, TWITTER_PAGE, INSTAGRAM_PAGE, LINKEDIN_PAGE],
        telephone: '+525618394661',
        priceRange: '$$',
        address: {
            '@type': 'PostalAddress',
            streetAddress: 'Havre 77, Juárez',
            addressLocality: 'Juárez, Cuauhtémoc',
            postalCode: '06600',
            addressRegion: 'Ciudad de México',
            addressCountry: 'MEXICO',
        },
        department: [
            {
                '@type': 'Store',
                name: 'YEMA Nuevo Leon',
                description:
                    '¡Tu tienda YEMA en la Escandón! YEMA: el primer súper consciente de México. Así de bueno. Así de fácil.',
                image: {
                    '@type': 'ImageObject',
                    url: 'https://storage.googleapis.com/yema-plm/cms-prod/original_images/_NUEVO_LEON_2.jpg',
                },
                priceRange: '$$',
                telephone: '+525524632721',
                address: {
                    '@type': 'PostalAddress',
                    streetAddress: 'Nuevo León 257',
                    addressLocality: 'Escandón II, Miguel Hidalgo',
                    postalCode: '11800',
                    addressRegion: 'Ciudad de México',
                    addressCountry: 'MEXICO',
                },
            },
            {
                '@type': 'Store',
                name: 'YEMA Coyoacán',
                description:
                    '¡Tu tienda YEMA en Coyoacán! YEMA: el primer súper consciente de México. Así de bueno. Así de fácil.',
                image: {
                    '@type': 'ImageObject',
                    url: 'https://storage.googleapis.com/yema-plm/cms-prod/original_images/_APERTURA-COYO.jpg',
                },
                priceRange: '$$',
                // "telephone": "",
                address: {
                    '@type': 'PostalAddress',
                    streetAddress: 'Plaza La Estación, Av. Miguel Ángel de Quevedo 279',
                    addressLocality: 'Romero de Terreros, Coyoacán',
                    postalCode: '04310',
                    addressRegion: 'Ciudad de México',
                    addressCountry: 'MEXICO',
                },
            },
            {
                '@type': 'Store',
                name: 'YEMA Bosques',
                description:
                    '¡Tu tienda YEMA en Bosques! YEMA: el primer súper consciente de México. Así de bueno. Así de fácil.',
                image: {
                    '@type': 'ImageObject',
                    url: 'https://storage.googleapis.com/yema-plm/cms-prod/original_images/_TIENDA_GLD1.jpg',
                },
                priceRange: '$$',
                // "telephone": "",
                address: {
                    '@type': 'PostalAddress',
                    streetAddress: 'Av. Stim 77',
                    addressLocality: 'Cuajimalpa de Morelos',
                    postalCode: '05129',
                    addressRegion: 'Ciudad de México',
                    addressCountry: 'MEXICO',
                },
            },
            {
                '@type': 'Store',
                name: 'YEMA Guadalajara, Patria',
                description:
                    '¡Tu tienda YEMA en Guadalajara Patria! YEMA: el primer súper consciente de México. Así de bueno. Así de fácil.',
                image: {
                    '@type': 'ImageObject',
                    url: 'https://storage.googleapis.com/yema-plm/cms-prod/original_images/NOM-pasillos-tienda-yema.jpg',
                },
                priceRange: '$$',
                // "telephone": "",
                address: {
                    '@type': 'PostalAddress',
                    streetAddress: 'Av. Patria 888',
                    addressLocality: 'Jardines Universidad',
                    postalCode: '45110',
                    addressRegion: 'Zapopan, Jalisco',
                    addressCountry: 'MEXICO',
                },
            },
            {
                '@type': 'Store',
                name: 'YEMA Guadalajara, Rubén Darío',
                description:
                    '¡Tu tienda YEMA en Guadalajara Rubén Darío! YEMA: el primer súper consciente de México. Así de bueno. Así de fácil.',
                image: {
                    '@type': 'ImageObject',
                    url: 'https://storage.googleapis.com/yema-plm/cms-prod/original_images/tienda-nuevo-leon.png',
                },
                priceRange: '$$',
                // "telephone": "",
                address: {
                    '@type': 'PostalAddress',
                    streetAddress: 'Av. Rubén Darío 425',
                    addressLocality: 'Circunvalación Vallarta',
                    postalCode: '44680',
                    addressRegion: 'Guadalajara, Jalisco',
                    addressCountry: 'MEXICO',
                },
            },
        ],
    }),
};

const Index = ({ pageContent, staticContent }) => {
    const router = useRouter();
    const authToken = useSelector(({ authentication }) => authentication.token);
    const dispatch = useDispatch();

    usePixelSoicosRetargeting();

    useEffect(() => initializeUserContext({ authToken, dispatch }), [authToken, router.asPath]);

    if (!router.isFallback && (!pageContent || !pageContent.data || pageContent.error)) {
        trackError({
            label: 'No Homepage Found',
            extras: {
                data: pageContent,
                error: pageContent ? pageContent.error : null,
            },
        });
        return <NotFoundLayout staticContent={staticContent} />;
    }

    return (
        <>
            <Head>
                <script
                    id='yema-rich-content'
                    type='application/ld+json'
                    dangerouslySetInnerHTML={homepageSchemaMarkup}
                />
            </Head>

            <PageLayout fullHeader={true} footerType='full' staticContent={staticContent}>
                <CMS_Page loading={router.isFallback} content={get_(pageContent, ['data'])} />
            </PageLayout>
        </>
    );
};

export async function getStaticProps(context) {
    try {
        const { reduxStore, apolloClient } = await initialize({
            withReduxStore: true,
            withApolloClient: true,
            withUserContext: false,
        });

        const menus = await getMenus(context, apolloClient);
        const page = await getHomepage(apolloClient, context.preview);

        const metaTags = {
            title: get_(page, ['data', 'seoTitle']),
            description: get_(page, ['data', 'searchDescription']),
            image: get_(page, ['data', 'seoImage', 'file']),
            shouldIndex: get_(page, ['data', 'seoShouldIndex']),
            canonicalUrl: get_(page, ['data', 'seoCanonicalUrl']),
        };

        return {
            props: {
                initialReduxState: reduxStore.getState(),
                // initialApolloState: apolloClient.cache.extract(),
                pageContent: page,
                staticContent: {
                    ...menus,
                    metaTags,
                    preview: !!context.preview,
                },
            },

            // Re-generate the page:
            // - when a request comes in
            // - at most once every X seconds
            revalidate: 120,
        };
    } catch (error) {
        // The API most likely died
        console.error(error);
        return {
            props: {},
        };
    }
}

export default Index;
