import React from 'react';
import Head from 'next/head';
import PageLayout from './Page.layout';
import NotFound from '@/yema-designkit/webkit/molecules/NotFound';

const NotFoundLayout = ({ staticContent }) => {
    return (
        <>
            <Head>
                <meta name='robots' content='noindex' />
            </Head>
            <PageLayout
                staticContent={{
                    ...staticContent,
                    metaTags: {
                        title: 'Página no encontrada',
                        shouldIndex: false,
                        dismissSeoTags: true,
                    },
                }}
            >
                <NotFound />
            </PageLayout>
        </>
    );
};

export default NotFoundLayout;
