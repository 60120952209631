import React from 'react';
import useScript from 'designkit/src/hooks/useScript';

const SOICOS_PIXEL_SCRIPT_LINK = 'https://ad.soicos.com/rtg.php?pid=11885&s=.js';

const usePixelSoicosRetargeting = () => {
    const [scriptIsReady, scriptErrorCode] = useScript(
        process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production' ||
            process.env.NEXT_PUBLIC_DEPLOY_ENV === 'staging'
            ? SOICOS_PIXEL_SCRIPT_LINK
            : ''
    );
};

export default usePixelSoicosRetargeting;
