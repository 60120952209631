import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import * as PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { get_, rules, dataTransformers } from 'sf-modules';
import { useStateEngine } from '@yema/commerce';
import { getProductsAvailability } from 'functions/fnRequestAPIs/fnProductsAPI';
import { fetchProductsPromotions } from 'functions/fnRequestAPIs/fnProductsPromotion';
import Section from '@/yema-designkit/webkit/layouts/Section';
import NotFound from '@/yema-designkit/webkit/molecules/NotFound';

/** Import CMS Components */
const CMS_BannerSlider = dynamic(() => import('./CMS_BannerSlider'));
const CMS_Author = dynamic(() => import('./CMS_Author'));
const CMS_Banner = dynamic(() => import('./CMS_Banner'));
const CMS_BannerSmall = dynamic(() => import('./CMS_BannerSmall'));
const CMS_CardForm = dynamic(() => import('./CMS_CardForm'));
const CMS_Reviews = dynamic(() => import('./CMS_Reviews'));
const CMS_RowCardGroup = dynamic(() => import('./CMS_RowCardGroup'));
const CMS_Body = dynamic(() => import('./CMS_Body'));
const CMS_BodyTitle = dynamic(() => import('./CMS_BodyTitle'));
const CMS_ContactUsCard = dynamic(() => import('./CMS_ContactUsCard'));
const CMS_Questions = dynamic(() => import('./CMS_Questions'));
const CMSAlgoliaProducts = dynamic(() => import('./CMS_AlgoliaProducts'));

/**
 * These objects' keys are required to match wagtail component's type
 * DO NOT MODIFY these keys, unless they are modified in Wagtail first
 */
const components = {
    slider: CMS_BannerSlider,
    banner: CMS_Banner,
    reviews: CMS_Reviews,
    cardGroups: CMS_RowCardGroup,
    body: CMS_Body,
    body_content: CMS_Body,
    body_title: CMS_BodyTitle,
    body_title_block: CMS_BodyTitle,
    body_contact_us_card: CMS_ContactUsCard,
    body_questions: CMS_Questions,
    body_card_group: CMS_RowCardGroup,
    body_banner_slider: CMS_BannerSlider,
    body_home_slider: CMS_BannerSlider,
    body_banner: CMS_Banner,
    body_banner_small: CMS_BannerSmall,
    body_form: CMS_CardForm,
    body_written_by: CMS_Author,
    body_algolia_card_group: CMSAlgoliaProducts,
};

const CMSPageSkeleton = () => {
    const duration = 1.2;
    return (
        <div>
            <Skeleton duration={duration} height={280} />
            <Section className='pb-0'>
                <div className='container mb-12'>
                    <div className='row'>
                        {[1, 2, 3, 4, 5, 6].map((i) => (
                            <div key={i} className='col-span-4 mb-6'>
                                <Skeleton duration={duration} height={80} />
                                <div className='mt-1 text-center'>
                                    <Skeleton duration={duration} width='90%' />
                                    <div className='mb-4' />
                                    <Skeleton duration={duration} width='50%' />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Section>
        </div>
    );
};

const CMS_PageBody = ({ content }) => {
    const [{ catalog }] = useStateEngine();
    const [contentParsed, setContentParsed] = useState(get_(content, ['body']));
    const [renderedTime, setRenderedTime] = useState('');

    const refetchAvailabilitiesAndPromotions = async ({
        shouldRefetchAvailabilities = false,
        shouldRefetchPromotions = false,
    }) => {
        const fetchedPromotionsPromise = shouldRefetchPromotions
            ? fetchProductsPromotions({
                  gtinsToFetch: content.productsGtinsInPage,
                  plmIds: content.plmIdsInPage,
                  bundlesIdToFetch: content.bundlesIdsInPage,
                  storeId: catalog?.store?.id,
              })
            : null;

        const fetchedAvailabilitiesPromise = shouldRefetchAvailabilities
            ? getProductsAvailability({
                  gtins: content.gtinsInPage,
                  plmIds: content.plmIdsInPage,
                  zipCode: catalog ? catalog.zipCode : null,
                  storeId: catalog?.store?.id,
              })
            : null;

        Promise.all([fetchedPromotionsPromise, fetchedAvailabilitiesPromise]).then((res) => {
            const fetchedPromotions = get_(res, [0]);
            const fetchedAvailabilities = get_(res, [1]);

            if (
                fetchedAvailabilities ||
                fetchedPromotions.productsPromotions ||
                fetchedPromotions.bundlesPromotions
            ) {
                const data =
                    shouldRefetchAvailabilities && shouldRefetchPromotions
                        ? [...get_(content, ['body'])]
                        : [...contentParsed];

                data.filter((item) => item.type === 'card_group').map((group) => {
                    get_(group, ['value', 'cards'], [])
                        ?.filter((card) => card.layout === 'product')
                        ?.map((card) => {
                            let gtin = card.title;

                            // Insert availability information
                            if (
                                card.product &&
                                shouldRefetchAvailabilities &&
                                fetchedAvailabilities
                            ) {
                                const availability = fetchedAvailabilities?.availabilities?.find(
                                    (p) => p.gtin === gtin
                                );
                                if (availability) {
                                    card.product.availability = dataTransformers.parseAvailability(
                                        catalog?.zipCode,
                                        card.product,
                                        availability
                                    );
                                } else {
                                    card.product.availability = {
                                        checkoutMaxQuantity: 0,
                                        availableOnZipCode: false,
                                        itemsAvailable: 0,
                                        status: 'unavailable',
                                    };
                                }
                                card.product.catalogPrice = fetchedAvailabilities?.prices?.find(
                                    (x) => x.sku === gtin
                                );
                            }

                            // Insert promotion information
                            if (card.product && shouldRefetchPromotions) {
                                const promotion = card.isBundle
                                    ? fetchedPromotions.bundlesPromotions.find(
                                          (p) => p.sku === gtin
                                      )
                                    : fetchedPromotions.productsPromotions.find(
                                          (p) => p.gtin === gtin
                                      );

                                if (promotion) {
                                    const firstApplicableRule = rules.getFirstApplicableRule(
                                        get_(promotion, ['applicableRules'], []),
                                        gtin
                                    );
                                    card.product.firstApplicableRule = {
                                        title: get_(firstApplicableRule, ['title']),
                                        icon: get_(firstApplicableRule, ['campaignIcon']),
                                    };
                                }
                            }
                        });
                });

                setContentParsed(data);
                setRenderedTime(new Date().getTime().toString(36));
            }
        });
    };

    useEffect(() => {
        if (catalog && catalog.createdAt && catalog.createdAt !== '') {
            refetchAvailabilitiesAndPromotions({
                shouldRefetchAvailabilities: true,
                shouldRefetchPromotions: true,
            });
        }

        // This useEffect should be triggered only on content change (i.e. client-side navigation)
    }, [content && content.slug]);

    useEffect(() => {
        if (catalog && catalog.createdAt && catalog.createdAt !== '') {
            refetchAvailabilitiesAndPromotions({
                shouldRefetchAvailabilities: true,
                shouldRefetchPromotions: true, // Promotions depends on store change
            });
        }

        // This useEffect should be triggered only on catalog settings change
    }, [catalog && catalog.createdAt]);

    if (!contentParsed || !contentParsed.length) return <CMSPageSkeleton />;

    return (
        <div className={contentParsed && contentParsed.length ? 'mb-12' : ''}>
            {contentParsed.map((element, index) => {
                if (!element.type) return '';

                const CustomComponent = components[`body_${element.type}`];
                if (!CustomComponent) return '';

                return (
                    <CustomComponent
                        key={`body-elt-${index}-${renderedTime}`}
                        blockId={index}
                        slug={content.slug}
                        typeName={content.__typename}
                        content={element.value}
                        isBlog={element.type === 'banner' || element.type === 'body_banner'}
                    />
                );
            })}
        </div>
    );
};

const CMS_Page = ({ loading, content }) => {
    if (loading) return <CMSPageSkeleton />;

    if (!loading && (!content || !content.body)) return <NotFound />;

    if (!content || (Array.isArray(content.body) && !content.body.length)) return '';

    if (typeof content.body === 'string')
        return (
            <div className='mb-12'>
                <CMS_Body content={content.body} />
            </div>
        );

    return <CMS_PageBody content={content} />;
};

CMS_Page.defaultProps = {};

CMS_Page.propTypes = {
    loading: PropTypes.bool,
    content: PropTypes.object,
};

export default React.memo(CMS_Page);
